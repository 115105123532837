import React, { useState, useEffect, Suspense, lazy } from "react";
import { Element } from "react-scroll";

import "./App.css";
import Navbar from "./Navbar";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NotFound from "./NotFound";
import AdSenseAd from "./AdSenseAd";

const Home = lazy(() => import("./Home"));
const About = lazy(() => import("./About"));
const Contact = lazy(() => import("./Contact"));

function App() {
    const [shadow, setShadow] = useState(false);
    const [activeSection, setActiveSection] = useState("home");

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setShadow(currentScrollPos > 0);

            const homeElement = document.getElementById("home");
            const aboutElement = document.getElementById("about");
            const contactElement = document.getElementById("contact");

            const homeOffset = homeElement ? homeElement.offsetTop : 0;
            const aboutOffset = aboutElement ? aboutElement.offsetTop - 70 : 0;
            const contactOffset = contactElement
                ? contactElement.offsetTop - 70
                : 0;

            if (currentScrollPos >= contactOffset) {
                setActiveSection("contact");
            } else if (currentScrollPos >= aboutOffset) {
                setActiveSection("about");
            } else {
                setActiveSection("home");
            }
        };

        // Add the event listener with the passive option
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            // Remove the event listener
            window.removeEventListener("scroll", handleScroll, {
                passive: true,
            });
        };
    }, []);

    const Main = (
        <div className="flex flex-col items-center w-full">
            <header className="fixed top-0 z-50  flex justify-center items-center w-full">
                <nav className="w-full">
                    <Navbar shadow={shadow} activeSection={activeSection} />
                </nav>
            </header>
            <main className="w-full pt-16 overflow-none">
                <Suspense fallback={<div>Loading...</div>}>
                    <Element
                        name="home"
                        id="home"
                        className="p-6 bg-[#f8f9fa] w-full h-full"
                    >
                        <Home />
                    </Element>
                    <Element
                        name="about"
                        id="about"
                        className="p-6 bg-[#f8f9fa] w-full h-full"
                    >
                        <About />
                    </Element>
                    <Element
                        name="contact"
                        id="contact"
                        className="p-6 bg-[#f8f9fa] w-full h-full"
                    >
                        <Contact />
                    </Element>
                </Suspense>
                <AdSenseAd
                    adSlot="1234567890"
                    adFormat="auto"
                    adLayout="in-article"
                    adLayoutKey="-gw-3+1f-3d+2z"
                />
            </main>
            <footer className="w-full p-4 bg-gray-200 text-center">
                <p>
                    &copy; {new Date().getFullYear()} AHVW. All rights reserved.
                </p>
            </footer>
        </div>
    );

    return (
        <Router>
            <Routes>
                <Route path="/" element={Main} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default App;
