import { useState } from "react";
import { Link } from "react-scroll";
import { MenuIcon } from "lucide-react";
import { motion } from "framer-motion";

const Navbar = ({ shadow, activeSection }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <nav
            className={`fixed w-full bg-white z-10 ${
                shadow ? "shadow-md" : ""
            } transition`}
        >
            <div className="flex justify-between items-center p-4">
                <div className="flex items-center">
                    <img
                        src="/logo.jpg"
                        width="30"
                        height="30"
                        className="d-inline-block align-center m-2"
                        alt="Logo"
                    />
                    <span className="text-lg font-bold">AHVW</span>
                </div>
                <div className="hidden md:flex space-x-4">
                    <div className="group flex flex-col items-center">
                        <Link
                            to="home"
                            smooth={false}
                            duration={500}
                            offset={-70}
                            className={`cursor-pointer text-zinc-800 no-underline transition ease-in-out group-hover:-translate-y-[3px]`}
                        >
                            Home
                        </Link>
                        <motion.div
                            className={`${
                                activeSection === "home"
                                    ? "border-b-2 border-black"
                                    : ""
                            } transition ease-in-out group-hover:scale-x-110`}
                            initial={{ width: 0 }}
                            animate={{
                                width: activeSection === "home" ? "100%" : 0,
                            }}
                            transition={{ duration: 0.3 }}
                        />
                    </div>
                    <div className="group flex flex-col items-center">
                        <Link
                            to="about"
                            smooth={false}
                            duration={500}
                            offset={-70}
                            className={`cursor-pointer text-zinc-800 no-underline transition ease-in-out group-hover:-translate-y-[3px]`}
                        >
                            About
                        </Link>
                        <motion.div
                            className={`${
                                activeSection === "about"
                                    ? "border-b-2 border-black"
                                    : ""
                            } transition ease-in-out group-hover:scale-x-110`}
                            initial={{ width: 0 }}
                            animate={{
                                width: activeSection === "about" ? "100%" : 0,
                            }}
                            transition={{ duration: 0.3 }}
                        />
                    </div>
                    <div className="group flex flex-col items-center">
                        <Link
                            to="contact"
                            smooth={false}
                            duration={500}
                            offset={-70}
                            className={`cursor-pointer text-zinc-800 no-underline transition ease-in-out group-hover:-translate-y-[3px]`}
                        >
                            Contact
                        </Link>
                        <motion.div
                            className={`${
                                activeSection === "contact"
                                    ? "border-b-2 border-black"
                                    : ""
                            } transition ease-in-out group-hover:scale-x-110`}
                            initial={{ width: 0 }}
                            animate={{
                                width: activeSection === "contact" ? "100%" : 0,
                            }}
                            transition={{ duration: 0.3 }}
                        />
                    </div>
                </div>
                <button className="md:hidden" onClick={toggleMobileMenu}>
                    <MenuIcon />
                </button>
            </div>

            {mobileMenuOpen && (
                <div className="md:hidden flex flex-row items-center space-x-2">
                    <Link
                        to="home"
                        smooth={false}
                        duration={500}
                        offset={-70}
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        Home
                    </Link>
                    <Link
                        to="about"
                        smooth={false}
                        duration={500}
                        offset={-70}
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        About
                    </Link>
                    <Link
                        to="contact"
                        smooth={false}
                        duration={500}
                        offset={-70}
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        Contact
                    </Link>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
