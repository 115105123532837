import React, { useEffect } from "react";

const AdSenseAd = ({ adSlot, adFormat, adLayout, adLayoutKey }) => {
    useEffect(() => {
        if (window) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
    }, []);

    return (
        <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-your-publisher-id"
            data-ad-slot={adSlot}
            data-ad-format={adFormat}
            data-ad-layout={adLayout ? adLayout : ""}
            data-ad-layout-key={adLayoutKey ? adLayoutKey : ""}
        ></ins>
    );
};

export default AdSenseAd;
